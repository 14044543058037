<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
</script>

<template>
  <div class="account">
    <AccountSideNav />
    <section class="account-wrapper">
      <slot />
    </section>
  </div>
</template>

<style lang='scss' scoped>
.account {
  color: var(--c-text-primary);
  display: grid;
  grid-template-columns: max-content [stack] 1fr;
  grid-template-rows: [stack] 1fr;
  min-height: 100vh;

  @media (max-width: 540px) {
    grid-area: stack;
  }
}

.account-wrapper {
  --indent: 40px;
  padding: var(--indent) 60px;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: var(--indent);
  }
}
</style>